<template lang="pug">
div 
  b-container.container-lg
    b-card#profile
      Sidebar(:name="'Welcome, ' + user.name + '!'")
      
      div.d-flex.justify-content-center.align-items-center.h-75(v-if="!ready")
        b-spinner(label="Spinning")

      div.mx-3.my-2.py-3(v-if="ready")
        div#profileSection.mb-4.p-4.shadow.text-white.d-flex.align-items-center
          b-icon.my-auto(icon="person-circle" font-scale="3.5" variant="white")
          div.detailSection.ml-3.mr-auto.d-flex.flex-column
            h6.font-weight-bold {{ user.name }}
            h6.font-weight-bold(v-if="!user.role") No Role
            h6 {{ user.role }}
          b-icon.my-auto.ml-auto(icon="pencil-fill" font-scale="1.5" variant="white" button @click="editMode")

        b-list-group#buttonSection.shadow.border

          b-list-group-item#buttonList.py-3.px-4.text-dark.d-flex.align-items-center(button @click="$bvModal.show('viewBox')")
            b-icon.my-auto(icon="person" font-scale="2")
            div.ml-4.mr-auto
              h6.font-weight-bold View Profile
              small.font-italic Check your profile
            b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")
            
          b-list-group-item#buttonList.py-3.px-4.text-dark.d-flex.align-items-center(button @click="editMode")
            b-icon.my-auto(icon="pencil" font-scale="2")
            div.ml-4.mr-auto
              h6.font-weight-bold Edit Profile
              small.font-italic Make changes to your profile
            b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")
          
          router-link#buttonList.py-3.px-4.text-dark.d-flex.align-items-center(tag="b-list-group-item" to="/sessionForm")
            b-icon.my-auto(icon="file-earmark-plus" font-scale="2")
            div.ml-4.mr-auto
              h6.font-weight-bold Log Session
              small.font-italic Log a new session
            b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")

          router-link#buttonList.py-3.px-4.text-dark.d-flex.align-items-center(tag="b-list-group-item" to="/sessionList")
            b-icon.my-auto(icon="list-check" font-scale="2")
            div.ml-4.mr-auto
              h6.font-weight-bold Past Sessions
              small.font-italic View all past sessions
            b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")

          router-link#buttonList.py-3.px-4.text-dark.d-flex.align-items-center(tag="b-list-group-item" to="/calendar")
            b-icon.my-auto(icon="calendar3-week" font-scale="2")
            div.ml-4.mr-auto
              h6.font-weight-bold Calendar
              small.font-italic Check your calendar
            b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")

          a#buttonList.py-3.px-4.text-dark.d-flex.align-items-center(href="https://life-community.org/wp-content/uploads/2020/01/LCSS-Volunteer-Handbook.pdf" target="_blank" rel="noopener noreferrer")
            b-icon.my-auto(icon="book" font-scale="2")
            div.ml-4.mr-auto
              h6.font-weight-bold Handbook 
              small.font-italic View the Handbook
            b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")  

          b-list-group-item#buttonList.py-3.px-4.text-danger.d-flex.align-items-center(button @click="$bvModal.show('logOutBox')")
            b-icon.my-auto(icon="arrow-left-square" font-scale="2")
            div.ml-4.mr-auto
              h6.font-weight-bold Log Out
              small.font-italic Sign out your account
            b-icon.my-auto.ml-auto(icon="chevron-right" font-scale="1")
      
      //-View Modal
      b-modal#viewBox(centered header-bg-variant="primary" header-text-variant="white" hide-footer)
        template(#modal-header)
          b-icon.my-auto(icon="arrow-left" font-scale="1.5" @click="$bvModal.hide('viewBox')")
          h4.text-center.m-auto View Profile
        b-container#spacing(fluid)
          div.detailSection.mb-3.d-flex.justify-content-center.flex-column
            b-icon.mx-auto(icon="person-circle" font-scale="4" variant="dark")
            h5.font-weight-bold.mx-auto.mt-2(variant="primary") {{ user.name }}
          hr
          .d-flex.flex-wrap.justify-content-center
            b-badge.m-2(variant="danger" v-if="!user.role") No Main Role Assigned
            b-badge.m-2(variant="success") {{ user.role }}
            b-badge.m-2(v-for="(badge, index) in additionalRoleArray" :key="index" variant="primary") {{ badge }}
          hr
          .d-flex.flex-wrap
            p.h6 
              b-icon.mr-2(icon="bag-fill")
              span.font-weight-bold Organisation:
              b-badge.ml-2.py-1(:variant="user.org ? 'warning':'danger'") {{ user.org ? user.org : 'No Organisation Assigned' }}
          //- .d-flex.flex-wrap(v-if="this.vol['_crf69_programme_value@OData.Community.Display.V1.FormattedValue']")
          //-   p.h6 
          //-     b-icon.mr-2(icon="bag-fill")
          //-     span.font-weight-bold Programme:
          //-   p.ml-2 {{ this.vol["_crf69_programme_value@OData.Community.Display.V1.FormattedValue"] }}
          //- .d-flex.flex-wrap(v-if="this.vol['_crf69_scheme_value@OData.Community.Display.V1.FormattedValue']")
          //-   p.h6 
          //-     b-icon.mr-2(icon="bag-fill")
          //-     span.font-weight-bold Scheme:
          //-   p.ml-2 {{ this.vol["_crf69_scheme_value@OData.Community.Display.V1.FormattedValue"] }}
          hr
          .d-flex.flex-wrap
            p.h6 
              b-icon.mr-2(icon="envelope-fill")
              span.font-weight-bold Email:
            p.ml-2 {{ user.email }}
          .d-flex.flex-wrap
            p.h6 
              b-icon.mr-2(icon="phone-fill")
              span.font-weight-bold Phone:
            p.ml-2 {{ user.phone }}
          hr
          .d-flex.flex-wrap
            p.h6 
              b-icon.mr-2(icon="map-fill")
              span.font-weight-bold Address Line 1:
            p.ml-2 {{ vol?.crf69_Contact?.crf69_addressline1 }}
          .d-flex.flex-wrap
            p.h6 
              b-icon.mr-2(icon="map-fill")
              span.font-weight-bold Address Line 2:
            p.ml-2 {{ vol?.crf69_Contact?.crf69_addressline2 }}

      //-Edit Modal
      b-modal#editBox(centered header-bg-variant="primary" header-text-variant="white" hide-footer no-close-on-backdrop)
        template(#modal-header)
          b-icon.my-auto(icon="arrow-left" font-scale="1.5" @click="$bvModal.show('warningBox')")
          h4.text-center.m-auto Edit Profile
        b-container#spacing(fluid)
          div.detailSection.mb-3.d-flex.flex-column.align-items-center
            b-icon.mb-3(icon="person-circle" font-scale="4" variant="dark")
            h6.font-weight-bold {{ user.name }}
            .d-flex.flex-wrap.justify-content-center
              b-badge.m-2(variant="danger" v-if="!user.role") No Main Role Assigned
              b-badge.m-2(variant="danger" v-if="!user.org") No Organisation Assigned
              b-badge.m-2(variant="success") {{ user.role }}
              b-badge.m-2(v-for="(badge, index) in additionalRoleArray" :key="index" variant="primary") {{ badge }}
              b-badge.m-2(variant="warning") {{ user.org }}
          hr
          div.text-center(v-if="loading")
            b-spinner(label="Spinning")
          div(v-if="loading == false")
            b-row.mx-1
                label Email: 
                b-input.field(:placeholder="user.email" v-model="formdata.crf69_email")
            br
            b-row.mx-1
                label Contact No: 
                b-input.field(:placeholder="user.phone" v-model="formdata.crf69_number")
            br
            b-row.mx-1
                label Address Line 1: 
                b-input.field(v-model="formdata.crf69_addressline1" :placeholder="vol?.crf69_Contact?.crf69_addressline1")
            br
            b-row.mx-1
                label Address Line 2: 
                b-input.field(v-model="formdata.crf69_addressline2" :placeholder="vol?.crf69_Contact?.crf69_addressline2")
            .d-flex.justify-content-around.mt-4
              b-button#submitedit.px-3(@click="$bvModal.show('warningBox')" variant="outline-danger") Back
              b-button#submitedit.px-3(@click='submit' variant="outline-primary") Submit
      b-modal#successBox(centered title="Status" header-bg-variant="success" header-text-variant="white")
        h3.text-dark Update Successful
        template(#modal-footer)
          b-button.px-3(variant="primary" size="md" class="float-right" @click="refreshPage") Ok

      b-modal#warningBox(title="Warning" centered header-bg-variant="danger" header-text-variant="white")
        h4  If you proceed to go back, all changes made will not be saved. Are you sure?
        template(#modal-footer)
            b-button(variant="danger" size="md" class="float-right" @click="$bvModal.hide('warningBox')") No
            b-button(variant="primary" size="md" class="float-right" @click="hideWarningAndEditBox") Yes

      b-modal#logOutBox(title="Log Out?" centered header-bg-variant="danger" header-text-variant="white")
        h4  Are you sure?
        template(#modal-footer)
            b-button(variant="danger" size="md" class="float-right" @click="$bvModal.hide('logOutBox')") No
            b-button(variant="primary" size="md" class="float-right" @click="logout") Yes
</template>
<script>
import Sidebar from './components/sidebar';
import formmixin from './formmixin.js';
//import axios from 'axios';
export default {
  mixins: [formmixin],
  name: "sessionsMb",
    components: {
          Sidebar
      },
    data() {
      return {
        ready:false,
        loading: false,
        editmode: false,
        pbtn: false,
        vol:null,
        head: { blank: true, width: 70, height: 70, class: 'm1' },
        additionalRoleArray:[],
        formdata:{
          crf69_email:"",
          crf69_number:"",
          crf69_addressline1:"",
          crf69_addressline2:"",
          _crf69_contact_value:""
        },
        user:{
          name: this.$root.user?.crf69_volunteername,
          role: this.$root.user?.["crf69_role@OData.Community.Display.V1.FormattedValue"],
          email: this.$root.user?.crf69_volunteeremail,
          phone: this.$root.user?.crf69_volunteerphonenumber,
          org: this.$root.user?.crf69_volunteerorganisation,
          address: this.$root.user?.crf69_volunteeraddress,
          access: this.$root.user?.crf69_volunteeraccess,
        }
      };
    },
    async mounted(){
      this.$root.goto("profile")
      const loggedOut = localStorage.getItem('loggedOut');
      if (loggedOut || this.user.access === 1) {
        this.$router.push(`/landing`);
        return;
      }
      let $ = this.$root.create_client();
      let arr = [
        $.get("/api/profile").then(({data})=>{//bad naming convention probably.
          this.vol = data[0];
          return data;
        }), 
      ];
      Promise.all(arr).then(()=>{
        this.ready = true;
        if(this.vol["crf69_additionalroles@OData.Community.Display.V1.FormattedValue"]){
          this.additionalRoleArray = this.vol["crf69_additionalroles@OData.Community.Display.V1.FormattedValue"].split('; ')
        }
        // console.log(this.vol);
      }).catch(()=>{
        alert("Unable to connect to the system. Please try your submission another time.");
      })
    },
    methods:{
      refreshPage(){
        location.href = "/profile"
      },
      logout(){
        localStorage.clear();
        localStorage.setItem('loggedOut', true);
        window.location.href="/landing?logout=1"
      },
      edit(){
        this.editmode = !this.editmode;
      },
      editMode(){
        this.$bvModal.show('editBox')
        this.formdata.crf69_email = this.user.email
        this.formdata.crf69_number = this.user.phone
        this.formdata.crf69_addressline1 = this.vol?.crf69_Contact?.crf69_addressline1
        this.formdata.crf69_addressline2 = this.vol?.crf69_Contact?.crf69_addressline2
      },
      hideWarningAndEditBox(){
        this.$bvModal.hide('editBox')
        this.$bvModal.hide('warningBox')
      },
      submit() {
        this.loading =!this.loading
        let profobj = this.finalData;
        let contactid = this.$root.user?._crf69_contact_value;
        console.log(contactid)
        console.log(profobj, contactid); //WORKSSSSSS
        let $ = this.$root.create_client();
        $.post("/api/profile", {profobj, contactid}).then((rsp)=>{
          if(!rsp.data.success){
            console.log('err1');
            throw "Submission issue";
          }
          this.loading =!this.loading
          this.$bvModal.show('successBox')
          console.log('out of FE');
        }).catch(err=>{
          console.log('err2'); //throws this error 
          console.log(err);
          alert("Unable to submit your entry. Please check your internet connection and try again. If the problem persists, contact us directly.");
          this.$bvModal.hide('editBox')
      })

  }

    }
  }
  </script>
  <style scoped>
  .field.form-control{
    font-family: sans-serif;
    font-size: 16px;
    font-weight:300;
    color:#1D3557;
  }

  #profileSection{
    border-radius:16px;
    background-color:#26263e
  }

  #buttonSection{
    border-radius:12px
  }

  #buttonList:active{
    background-color: #26263e;
    color:white !important;
    transition-duration: 0.25s;
  }

  label{
    font-family: sans-serif;
    font-size: 16px;
    font-weight:300;
  }

  </style>
