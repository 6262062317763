<template lang="pug">
div
    b-container.container-lg
        b-card#calendar
            Sidebar(name="Session Details")
            div.d-flex.justify-content-center.align-items-center.h-75(v-if="!ready")
                b-spinner(label="Spinning")
            div.h-100(v-if="ready")
                b-tabs(pills card fill)
                    b-tab(title="Info" active)
                        //-Main User Div
                        div.mx-3.my-2.p-3.bg-lightinfo.shadow.rounded
                            h5 {{ profileData.crf69_volunteername}}
                            div.d-flex.flex-wrap(v-if="sessionDetailData.crf69_Beneficiary")
                                p.h6
                                    b-icon.mr-2(icon="person-fill")
                                    span.font-weight-bold Beneficiary:
                                p.ml-2 {{sessionDetailData.crf69_Beneficiary.crf69_beneid}}
                            b-badge.mr-1(variant="success" v-if="sessionDetailData.crf69_membertype == 955870000") {{ sessionDetailData["crf69_membertype@OData.Community.Display.V1.FormattedValue"] }}
                            b-badge.mr-1(variant="success" v-if="sessionDetailData.crf69_membertype == 955870001") {{ sessionDetailData["crf69_membertype@OData.Community.Display.V1.FormattedValue"] }}
                            b-badge.mr-1(variant="warning" v-if="sessionDetailData.crf69_membertype == 955870002") {{ sessionDetailData["crf69_membertype@OData.Community.Display.V1.FormattedValue"] }}
                            b-badge(variant="success" v-if="sessionDetailData.crf69_attendance == 955870000") {{ sessionDetailData["crf69_attendance@OData.Community.Display.V1.FormattedValue"] }}
                            b-badge(variant="danger" v-if="sessionDetailData.crf69_attendance == 955870001") {{ sessionDetailData["crf69_attendance@OData.Community.Display.V1.FormattedValue"] }}
                            b-badge(variant="success" v-if="sessionDetailData.crf69_attendance == 955870002") {{ sessionDetailData["crf69_attendance@OData.Community.Display.V1.FormattedValue"] }}
                        //-Activiy Div
                        div.mx-3.my-2.p-3.bg-lightinfo.shadow.rounded
                            h5(v-if="!sessionDetailData.crf69_Activities") Volunteering Activity
                            h5(v-else-if="!sessionDetailData.crf69_Activities._crf69_parentrecurringactivity_value") {{ sessionDetailData.crf69_Activities.crf69_activityid}}
                            h5(v-else-if="sessionDetailData.crf69_Activities._crf69_parentrecurringactivity_value") {{ sessionDetailData.crf69_Activities["_crf69_parentrecurringactivity_value@OData.Community.Display.V1.FormattedValue"]}}
                            b-badge.mr-1(variant="success" v-if="!sessionDetailData.crf69_Activities||!sessionDetailData.crf69_Activities._crf69_parentrecurringactivity_value") One Off Activity
                            b-badge.mr-1(variant="success" v-else-if="sessionDetailData.crf69_Activities._crf69_parentrecurringactivity_value") Recurring Activity
                            hr
                            div.d-flex.flex-wrap(v-if="sessionDetailData.crf69_meetingplace")
                                p.h6 
                                    b-icon.mr-2(icon="map-fill")
                                    span.font-weight-bold Location:
                                p.ml-2(v-if="sessionDetailData.crf69_meetingplace && sessionDetailData.crf69_meetingplace != '955870003'") {{ sessionDetailData["crf69_meetingplace@OData.Community.Display.V1.FormattedValue"]  }}
                                p.ml-2(v-if="sessionDetailData.crf69_meetingplacenotes && sessionDetailData.crf69_meetingplace == '955870003'") {{ sessionDetailData.crf69_meetingplacenotes  }}
                            div.d-flex.flex-wrap
                                p.h6 
                                    b-icon.mr-2(icon="calendar-check-fill")
                                    span.font-weight-bold Start Time:
                                p.ml-2 {{ formatDate(sessionDetailData.crf69_datetime) }}
                            div.d-flex.flex-wrap
                                p.h6 
                                    b-icon.mr-2(icon="calendar2-x-fill")
                                    span.font-weight-bold End Time:
                                p.ml-2 {{ formatDate(sessionDetailData.crf69_attendanceend) }}
                        //-Misc Div
                        div.mx-3.my-2.p-3.bg-lightinfo.shadow.rounded(v-if="this.temperamentMentee.length>0||this.familyMentee.length>0||this.topicDiscussed.length>0")
                            div(v-if="topicDiscussed.length>0")
                                div.d-flex 
                                    h5 Topics/Issues
                                    b-icon.ml-auto(icon="plus-circle" @click="$bvModal.show('misc_notes_modal')")
                                b-badge.mr-2(v-for="(badge, index) in topicDiscussed"  :key="index" variant="primary") {{ badge }}
                            hr
                            div(v-if="temperamentMentee.length>0")
                                div.d-flex 
                                    h5 Mentee's Temperament
                                    b-icon.ml-auto(icon="plus-circle" @click="$bvModal.show('misc_notes_modal')")
                                b-badge.mr-2(v-for="(badge, index) in temperamentMentee" :key="index" variant="primary") {{ badge }}
                            hr
                            div(v-if="familyMentee.length>0")
                                div.d-flex 
                                    h5 Family Situation
                                    b-icon.ml-auto(icon="plus-circle" @click="$bvModal.show('misc_notes_modal')")
                                b-badge.mr-2(v-for="(badge, index) in familyMentee" :key="index" variant="primary") {{ badge }}
                    b-tab(title="Notes")
                        div.mx-2.my-2.p-2.bg-lightinfo.shadow.rounded
                            //-Caseworker Comment
                            div.my-2(v-if="sessionDetailData.crf69_comments_caseworker")
                                b-button.d-flex.justify-content-center(block @click="caseWorkerCommentCollapse = !caseWorkerCommentCollapse")
                                    //- span Comments to caseworkers
                                    span Objectives/Hopes you had for the session
                                    b-icon.ml-auto(:icon="caseWorkerCommentCollapse?'chevron-up' :'chevron-down'")
                                b-collapse(v-model="caseWorkerCommentCollapse")
                                    div.border.rounded.bg-white.my-2.p-2
                                        p {{sessionDetailData.crf69_comments_caseworker}}
                            //-Remark
                            div.my-2(v-if="sessionDetailData.crf69_remarks")
                                b-button.d-flex.justify-content-center(block @click="feedbackCollapse = !feedbackCollapse")
                                    span Feedback
                                    b-icon.ml-auto(:icon="feedbackCollapse?'chevron-up' :'chevron-down'")
                                b-collapse(v-model="feedbackCollapse")
                                    div.border.rounded.bg-white.my-2.p-2
                                        p {{sessionDetailData.crf69_remarks}}
                            //-Area of Concern
                            div.my-2(v-if="sessionDetailData.crf69_areasofconcern")
                                b-button.d-flex.justify-content-center(block @click="areaOfConcernCollapse = !areaOfConcernCollapse")
                                    span Concerns/challenges faced
                                    //- span Session notes, activities and/or any other areas of concern
                                    b-icon.ml-auto(:icon="areaOfConcernCollapse?'chevron-up' :'chevron-down'")
                                b-collapse(v-model="areaOfConcernCollapse")
                                    div.border.rounded.bg-white.my-2.p-2
                                        p {{sessionDetailData.crf69_areasofconcern}}
                            //-Challenges Faced
                            div.my-2(v-if="sessionDetailData.crf69_challengesfaced")
                                b-button.d-flex.justify-content-center(block @click="challengesFacedCollapse = !challengesFacedCollapse")
                                    span Notes/Reflections for my growth as a mentor
                                    //- span Challenges you faced & support needed
                                    b-icon.ml-auto(:icon="challengesFacedCollapse?'chevron-up' :'chevron-down'")
                                b-collapse(v-model="challengesFacedCollapse")
                                    div.border.rounded.bg-white.my-2.p-2
                                        p {{sessionDetailData.crf69_challengesfaced}}
                    template(#tabs-start)
                        b-nav-item(@click="$router.go(-1)" variant="danger") Back
                    template(#tabs-end)
                        b-nav-item(@click="editSessionDetail($route.params.attendanceid)") Edit
                b-modal(id="misc_notes_modal" scrollable centered hide-header)
                    div
                        b-button.d-flex.justify-content-center(block @click="topicNotesCollapse=!topicNotesCollapse")
                            span Topics/Issues Notes
                            b-icon.ml-auto(:icon="topicNotesCollapse?'chevron-up' :'chevron-down'")
                        b-collapse(v-model="topicNotesCollapse")
                            div.border.rounded.bg-white.shadow.p-2.mt-2
                                p {{sessionDetailData.crf69_topicnotes ? sessionDetailData.crf69_topicnotes :"Empty" }}
                    hr
                    div
                        b-button.d-flex.justify-content-center(block @click="temperamentNotesCollapse=!temperamentNotesCollapse")
                            span Mentee's Temperament Notes
                            b-icon.ml-auto(:icon="temperamentNotesCollapse?'chevron-up' :'chevron-down'")
                        b-collapse(v-model="temperamentNotesCollapse")
                            div.border.rounded.bg-white.shadow.p-2.mt-2
                                p {{sessionDetailData.crf69_temperamentnotes ? sessionDetailData.crf69_temperamentnotes: "Empty"}}
                    hr
                    div
                        b-button.d-flex.justify-content-center(block @click="familyNotesCollapse=!familyNotesCollapse")
                            span Family Situation Notes
                            b-icon.ml-auto(:icon="familyNotesCollapse?'chevron-up' :'chevron-down'")
                        b-collapse(v-model="familyNotesCollapse")
                            div.border.rounded.bg-white.shadow.p-2.mt-2
                                p {{sessionDetailData.crf69_familynotes ? sessionDetailData.crf69_familynotes: "Empty" }}
                    template(#modal-footer)
                        b-button(variant="danger" size="md" class="float-right" @click="$bvModal.hide('misc_notes_modal')") Close
</template>
<script>
//import axios from "axios";
import Sidebar from "./components/sidebar";
import dayjs from 'dayjs';

export default {
    name: "calendarMb",
    components: {
    Sidebar,
},
    data() {
        return {
        caseWorkerCommentCollapse:true,
        feedbackCollapse:true,
        areaOfConcernCollapse:true,
        challengesFacedCollapse:true,
        topicNotesCollapse:true,
        temperamentNotesCollapse:true,
        familyNotesCollapse:true,
        ready:false,
        dayjs,
        sessionDetailData:[],
        profileData:[],
        familyMentee:[],
        temperamentMentee:[],
        topicDiscussed:[],
        pbtn: false,
        head: { blank: true, width: 75, height: 75, class: "m1" },
        cal: null,
        context:null,
        selected:[],
        pastSessionModalShow:true,
        };
    },
    methods: {
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY hh:mm A')
        },
        formatDateOnly(date){
            return dayjs(date).format('YYYY-MM-DD')
        },
        formatTimeOnly(date) {
            return dayjs(date).format('hh:mm A')
        },
        editSessionDetail(id) {
            this.$router.push(`/sessionForm/edit/${id}`);
        },
    },
    computed: {
        
    },
    async mounted(){
        const attendance_id = this.$route.params.attendanceid;
        console.log(attendance_id);
        let $ = this.$root.create_client();
        let arr = [
            $.get(`/api/past_sessions?attendance_id=${attendance_id}`).then(({data})=>{//bad naming convention probably.
                this.sessionDetailData = data[0];
                return data;
            }),
            $.get("/api/profile").then(({data})=>{//bad naming convention probably.
                this.profileData = data[0];
                return data;
            }), 
        ]
        Promise.all(arr).then(()=>{
            this.ready = true;
            if(this.sessionDetailData["crf69_topicsissuesdiscussed@OData.Community.Display.V1.FormattedValue"]){
                this.topicDiscussed = this.sessionDetailData["crf69_topicsissuesdiscussed@OData.Community.Display.V1.FormattedValue"].split('; ')
            }
            if(this.sessionDetailData["crf69_tempermentofmentee@OData.Community.Display.V1.FormattedValue"]){
                this.temperamentMentee = this.sessionDetailData["crf69_tempermentofmentee@OData.Community.Display.V1.FormattedValue"].split('; ')
            }
            if(this.sessionDetailData["crf69_menteefamilysituation@OData.Community.Display.V1.FormattedValue"]){
                this.familyMentee = this.sessionDetailData["crf69_menteefamilysituation@OData.Community.Display.V1.FormattedValue"].split('; ')
            }
            console.log(this.sessionDetailData);
            console.log(this.profileData);
        }).catch(()=>{
            alert("There's seem to be an issue");
        })
        
    },
}


</script>
<style scoped>
.field.form-control {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #1d3557;
}
#button-grp{
    margin-top:5%;    
}
.f-len{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}
#btm{
    margin-top: 5px ;
}
</style>