<template lang="pug">
div.d-flex.head.p-3.align-items-center(style="background-color:#26263e")
    p.h1
        b-icon(v-b-toggle.sidebar-1 icon="list")
    h5.text-center.ml-3 {{name}}

    b-sidebar(id="sidebar-1" shadow)
        nav.mb-3
            b-nav(vertical)
                router-link.mx-4.my-2.p-2.text-left(to="/profile" tag="b-button" exact) 
                    div.h5
                        b-icon.mx-3(icon="person-fill")
                        |Profile / Overview
                router-link.mx-4.my-2.p-2.text-left(to="/sessionForm"  tag="b-button" exact)
                    div.h5
                        b-icon.mx-3(icon="file-earmark-plus-fill")
                        |Log Session
                router-link.mx-4.my-2.p-2.text-left(to="/sessionList"  tag="b-button" exact)
                    div.h5
                        b-icon.mx-3(icon="list-check")
                        |Past Sessions
                router-link.mx-4.my-2.p-2.text-left(to="/calendar"  tag="b-button" exact) 
                    div.h5
                        b-icon.mx-3(icon="calendar3-week-fill")
                        |Calendar
                //- a.mx-4.my-2.p-2.text-left(tag="b-button" href="https://life-community.org/wp-content/uploads/2020/01/LCSS-Volunteer-Handbook.pdf" target="_blank" rel="noopener noreferrer")
                //-     div.h5
                //-         b-icon.mx-3(icon="book")
                //-         |Hand Book        
                br
                b-button.mx-4.mt-5.p-2.text-left(@click="logout" variant="danger" exact) 
                    div.h5
                        b-icon.mx-3(icon="arrow-left-square-fill")
                        |Sign Out

//- div.sidebar

//-     div.title Menu
//-     div.menu-items
//-         router-link.side-btn(to="/profile" active-class="active" tag="button" exact)
//-             div.link-container Profile / Overview
//-         router-link.side-btn(to="/sessionForm" active-class="active" tag="button" exact)
//-             div.link-container Log Session
//-         router-link.side-btn(to="/calendar" active-class="active" tag="button" exact)
//-             div.link-container Calendar
//-         br
//-         hr(style="border-color:red;")
//-         b-link.side-btn(href="/landing?logout=1" active-class="active" tag="button" exact)
//-             div.link-container Sign Out

</template>
<script>
export default{
    name:"sidebarMb",
    props: ['name'],
    methods:{
        // clear_all_cookies() {
        //     var all_cookies = document.cookie.split(";");

        //     for (var i = 0; i < all_cookies.length; i++) {
        //         var single_cookie = all_cookies[i];
        //         var cookie_index = single_cookie.indexOf("=");
        //         var cookie_name = cookie_index > -1 ? all_cookies.substr(0, cookie_index) : single_cookie;
        //         document.cookie = cookie_name + "=;expires=" + new Date().toUTCString();
        //     }
            
        logout(){
            // document.cookie.split(";").forEach(function (cookie) { 
            //     document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); 
            // });
            localStorage.clear();
            localStorage.setItem('loggedOut', true);
            window.location.href="/landing?logout=1"
        }
    }
}
</script>
<style scoped>

.head{
    z-index: 99;
    width:100%;
    color:white;
}

</style>
