<template lang="pug">
div 
    b-container
        b-card#signinapp(no-body)
          b-card-body(style="width:100%")
            b-img#logo.mx-auto.d-block(fluid src="/LCSS-wo-background.png" alt="LCSS")
            h4#landingtitle.mb-2 LCSS VOLUNTEER APP
            hr
            h4#landingtitle Login
            //-b-card-body#btncon
              b-button-group(vertical)
                //b-button.ld-group(id="ms-signin" @click="" ) Sign in with Microsoft
                //b-button.ld-group(id="gg-signin") Sign in with Google
                //b-button#sms-signin(variant="link") Sign in via SMS
            br
            //- label.ml-2.h6 
            //-   strong Sign In Code
            b-input.signInInput.mb-3(v-model="c" size="lg"  placeholder="Sign in Code")
            //- label.ml-2.h6
            //-   strong Mobile Number
            .text-center.mb-3.d-flex.justify-content-center.align-items-center(v-if="!ready") 
              b-spinner(label="Spinning")
              h5.mx-2.my-auto Fetching Phone Number
            b-input.signInInput.mb-3(v-if="ready" v-model="n" size="lg" type="tel" autocomplete="tel" placeholder="Mobile Number")
            b-button.signInButton.mb-2(v-if="c && n" variant="secondary" size="lg" block @click="signinotp")
              b-icon.mr-3(icon="chat-right-text-fill")
              | Get OTP
            b-collapse.my-2(v-model="otpRequested")
              hr
              b-input.signInInput.mb-3(v-model="o" size="lg" placeholder="OTP" auto-complete="one-time-code")
              b-button.signInButton.mb-3(variant="secondary" size="lg" block @click="signin")
                b-icon.mr-3(icon="box-arrow-in-right")
                | Verify & Sign In
    //- div(v-show="!isShow") Profile page
    //-   b-container.container-lg
</template>

<script>
import axios from "axios";
export default {
  name: "landingMb",
  data() {

    let c = this.$route.query.c ||localStorage.getItem("lastuser") ||"";
    let n = localStorage.getItem("lastmobile")||"";

    return {
      c,
      n,
      ready:false,
      access: null,
      o:"",
      otpRequested:false
    };
  },

  async mounted(){
    if(!this.c){
      this.ready=true;
    }
    if(this.c){
      let arr =  [axios.get("/api/phonenumber").then(({data})=>{//bad naming convention probably.
        console.log(data[0])
        this.n=(data[0].crf69_Contact.crf69_number).replace(/\s/g, '')
        // this.access = data[0].crf69_mobileappaccess;
        return data;
      })];
      Promise.all(arr).then(()=>{
        this.ready = true;
      }).catch(()=>{
          alert("Sign-in again");
          this.ready=true;
      })

    }
  },

  methods:{
    async signinotp(){
      
      let {c,n} = this;
      c = c.replace(/[^a-fa-f0-9-]/g, "");
      let params= new URLSearchParams({c,n});
      try {
        await axios.get("/api/login?"+params.toString());
        this.otpRequested = true;
      } catch (error) {
        if (error) {
          console.log(error)
          alert(error.response.data)
        }
      }
    },
    signin(){
      console.log("logging in");
      localStorage.removeItem('loggedOut');
      let {c,n,o} = this;
      c = c.replace(/[^a-fa-f0-9-]/g, "");
      let params= new URLSearchParams({c,n,o});
      window.location.href= "/profile?"+params.toString();
      
    },
  
  },
  watch:{
    c(){
      this.otpRequested = false;
    },
    n(){
      this.otpRequested = false;
    }
  }
}
</script>

<style lang="scss" scoped>
#logo{
  width:18rem;
  height:18rem;
  object-fit: contain;
}

.signInInput{
  border-radius: 20px;
}

.signInButton{
  border-radius:20px;
}

label{
  color:#26263e;
}
</style>